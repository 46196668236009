import React, { FC, useState, useEffect, useRef } from "react";
import { deletePost } from "../../../../queries/feed/posts/deletePost.query";
import { getComments } from "../../../../queries/feed/posts/comments/getPostComments.query";
import { getReactions } from "../../../../queries/feed/posts/reactions/getPostReactions.query";
import { postReaction } from "../../../../queries/feed/posts/reactions/postPostReaction.query";
import { deleteReaction } from "../../../../queries/feed/posts/reactions/deletePostReaction.query";
import { FeedData } from '../../../../queries/feed/getFeed.query';
import { useMutation } from "react-query";
import { Menu, MenuItem, MenuProps, Typography, styled } from "@mui/material";
import { ErrorDto } from "../../../../types/error.dto";
import useUserData from "../../../../hooks/useUserData";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { Comment } from "./Comment";
import { CreateComment } from "./CreateComment";
import ReactPlayer from 'react-player'
import { CommentResponse } from "../../../../queries/feed/posts/comments/getPostComments.query";
import { ReactionResponse } from "../../../../queries/feed/posts/reactions/getPostReactions.query";
import { postComment, CommentData } from "../../../../queries/feed/posts/comments/postPostComment.query";
import Linkify from 'react-linkify';
import { formatDistanceToNow } from "date-fns";
import { ShareButtons } from "./ShareButtons";
import { usePathname } from "next/navigation";
import Image, { ImageLoaderProps } from 'next/image';
import { Avatar } from "@/components/atoms";
import { getPosts, PostData } from '../../../../queries/feed/posts/getPosts.query';

interface PostProps {
  post: PostData;
  postId: string;
  unauth?: boolean;
  detail?: boolean;
}

export const formattedDate = (date: string) => {
  return formatDistanceToNow(new Date(date));
};

export const Post: FC<PostProps> = ({ post, postId, unauth=false, detail=false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const pathname = usePathname();
  const router = useRouter();
  const userData = useUserData();
  const [deleted, setDeleted] = useState(false);
  const [comments, setComments] = useState<CommentResponse | null>(null);
  const [liked, setLiked] = useState(post.liked);
  const [showShareButtons, setShowShareButtons] = useState<boolean>(false);
  const hiddenAddress = post?.author.walletAddress?.substring(0, 6) +  "..." + post?.author.walletAddress?.substring(post.author.walletAddress?.length - 5, post.author.walletAddress?.length);
  const [commentsPage, setCommentsPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [likeCount, setLikeCount] = useState<number>(0);
  const [commentCount, setCommentCount] = useState<number>(0);
  const [directCommentCount, setDirectCommentCount] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const imageLoader = ( { src }: ImageLoaderProps ) => {
    return `${src}`
  }

  function isVideoFile(url: string) {
    const videoExtensions = [
      ".mp4", ".mkv", ".mov", ".avi", ".flv",
      ".ts", ".ps", ".mxf", ".lxf", ".gxf",
      ".3gp", ".webm", ".mpg", ".mpeg", ".qt"
    ];
  
    return videoExtensions.some(extension => url.endsWith(extension));
  }

  const getReactionCommentCountMut = useMutation(({ page, limit, usersIds, id }: { page: number, limit?: number, usersIds?: string[], id?: string[] }) => getPosts(page, limit, usersIds, id), {
    retry: false,
    onSuccess: (data) => {
      // console.log(data);
      setLikeCount(data.data[0].reactionsMetadata.likeCount);
      setCommentCount(data.data[0].commentsMetadata.count);
      setLiked(data.data[0].liked)
    },
    onError: (e: ErrorDto) => {
      console.error("Error: " + e)
    },
  });

  const deletePostMut = useMutation(deletePost, {
    onSuccess: async () => {
      // toast.success("Post successfully deleted.");
        setDeleted(true);
        detail && router.back();
    },
    onError: (e: ErrorDto) => {
      toast.error("Error deleting post: " + e);
    },
  });

  const getCommentsMut = useMutation(({ postId, parentId, page, limit }: { postId: string, parentId?: string, page?: number, limit?: number }) => getComments(postId, parentId, page, limit), {
    onSuccess: (data) => {
      // console.log(data)
      if(data.error){
        let timeoutId;
        timeoutId = setTimeout(() => {
          setComments(null);
        }, 500);
        clearTimeout(timeoutId);
      } else {
        setComments((prevComments) => ({
          data: [...(prevComments?.data || []), ...data.data.filter((comment: CommentData) => !comment.parentId).filter((comment: CommentData) => !comment.parentId)],
          meta: data.meta,
        }));
        setDirectCommentCount((data.meta.itemCount))
      }
    },
    onError: (e: ErrorDto) => {
      console.error("Error fetching comments", e);
    },
  });

  const uploadCommentMut = useMutation(({ postId, commentData }: { postId: string, commentData: CommentData }) => postComment(postId, commentData), {
    retry: false,
    onSuccess: async () => {
      setComments(null);
      getCommentsMut.mutate({postId: postId, page: 1});
      getReactionCommentCountMut.mutate({ page: 1, id: [postId] });
    },
    onError: (e: ErrorDto) => {
      toast.error("Error uploading post: " + e);
    }
  });

  const postReactionMut = useMutation(({ postId, type }: { postId: string, type: string }) => postReaction(postId, type), {
    retry: false,
    onSuccess: async () => {
      getReactionCommentCountMut.mutate({ page: 1, id: [postId] });
    },
    onError: (e: ErrorDto) => {
      console.error(e);
    }
  });

  const deleteReactionMut = useMutation(deleteReaction, {
    onSuccess: async () => {
      getReactionCommentCountMut.mutate({ page: 1, id: [postId] });
    },
    onError: (e: ErrorDto) => {
      console.error(e);
    },
  });

  const handleDeletePost = (postId: string) => {
    deletePostMut.mutate(postId);
    close();
  };

  const handleReactToPost = async (type: string) => {
    try {
      if (liked && type === "dislike") {
        await deleteReactionMut.mutateAsync(postId);
        return;
      }
      postReactionMut.mutate({ postId: postId, type: type});
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickSeeMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSeeMore = () => {
    setAnchorEl(null);
  };

  const handleOpenPost = () => {
    router.push(`/feed/post?postId=${postId}`);
    handleCloseSeeMore();
  };

  const handleClickShareButton = () => {
    setShowShareButtons(!showShareButtons);
  }

  const refreshComments = () => {
    setComments(null);
    setRefresh(!refresh);
    setCommentsPage(1);
  }

  const fetchNextCommentsPage = () => {
    if (comments?.meta.pageCount && (commentsPage < comments?.meta.pageCount)) {
      setCommentsPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    // getPostMut.mutate({ page: 1, id: [postId] });
    setCommentsPage(1);
    setLikeCount(post.reactionsMetadata.likeCount);
    setCommentCount(post.commentsMetadata.count);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsPlaying(true);
          } else {
            setIsPlaying(false);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (commentsPage > 0) {
      detail && getCommentsMut.mutate({ postId: postId, page: commentsPage });
      getReactionCommentCountMut.mutate({ page: 1, id: [postId] });
    }
  }, [commentsPage, refresh]);

  return deleted || !post ? (
    <></>
  ) : (
    <>
      <div className="row flex justify-between overflow-hidden rounded-2xl border bg-white p-0 m-0">
        <div className="column flex pb-[8px] pt-[8px] relative">
          <a href={`/@${post.author.username}`}>
            <Avatar
              size="medium"
              color="indigo"
              rounded
              className="ml-[-4px] mr-1 shrink-0 border-4 border-white"
              avatarUrl={post.author.avatarUrl}
              username={post.author.username}
            />
          </a>
          <div className="row flex mt-1 w-auto">
            <div className="column flex justify-between w-[80%]">
              <a href={`/@${post.author.username}`}>
                <span
                  onClick={() => { router.push(`/@${post.author.username}`) }}
                  className="cursor-pointer color-gray-900 text-[14px] font-medium"
                >
                  {post.author.firstName} {post.author.lastName}
                  {/* <Verified style={{ width: "16px", color: "#5850EC", marginLeft: "4px" }}/> */}
                </span>
              </a>
            </div>
            <div className="column flex justify-between">
              <a href={`/@${post.author.username}`} className="text-gray-500 text-[12px]">
                @{post.author.username}
                 {/* · {hiddenAddress} */}
              </a>
            </div>
          </div>

          <div className="flex column absolute right-3 top-4">
            <span className="text-gray-500 text-[12px] mr-2">{formattedDate(post.createdAt)}</span>
            <button disabled={unauth} onClick={handleClickSeeMore}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.66663 7.99967C2.66663 8.17649 2.73686 8.34605 2.86189 8.47108C2.98691 8.5961 3.15648 8.66634 3.33329 8.66634C3.5101 8.66634 3.67967 8.5961 3.8047 8.47108C3.92972 8.34605 3.99996 8.17649 3.99996 7.99967C3.99996 7.82286 3.92972 7.65329 3.8047 7.52827C3.67967 7.40325 3.5101 7.33301 3.33329 7.33301C3.15648 7.33301 2.98691 7.40325 2.86189 7.52827C2.73686 7.65329 2.66663 7.82286 2.66663 7.99967Z" stroke="#4B5563" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.33329 7.99967C7.33329 8.17649 7.40353 8.34605 7.52855 8.47108C7.65358 8.5961 7.82315 8.66634 7.99996 8.66634C8.17677 8.66634 8.34634 8.5961 8.47136 8.47108C8.59639 8.34605 8.66663 8.17649 8.66663 7.99967C8.66663 7.82286 8.59639 7.65329 8.47136 7.52827C8.34634 7.40325 8.17677 7.33301 7.99996 7.33301C7.82315 7.33301 7.65358 7.40325 7.52855 7.52827C7.40353 7.65329 7.33329 7.82286 7.33329 7.99967Z" stroke="#4B5563" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 7.99967C12 8.17649 12.0702 8.34605 12.1952 8.47108C12.3202 8.5961 12.4898 8.66634 12.6666 8.66634C12.8434 8.66634 13.013 8.5961 13.138 8.47108C13.2631 8.34605 13.3333 8.17649 13.3333 7.99967C13.3333 7.82286 13.2631 7.65329 13.138 7.52827C13.013 7.40325 12.8434 7.33301 12.6666 7.33301C12.4898 7.33301 12.3202 7.40325 12.1952 7.52827C12.0702 7.65329 12 7.82286 12 7.99967Z" stroke="#4B5563" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>

        <div className="row flex ">
          {post.title && (
            <span className="mb-2 text-[16px] text-[#1F2937] font-semibold">
              {post.title}
            </span>
          )}
          <Typography
            style={{
              fontSize: "14px",
              color: "#1F2937",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}  
          >
            <Linkify componentDecorator={(href: any, text: any, key: any) => (<a href={href} key={key} style={{ color: '#5850ec' }}>{text}</a>)}>
              {post.content}
            </Linkify>
          </Typography>
        </div>

        {post.resourceUrl ? (
          <div ref={videoRef} className="flex pb-[12px] pt-[16px]">
            {isVideoFile(post.resourceUrl) ? (
              <ReactPlayer playsinline playing={isPlaying} muted={true} url={post.resourceUrl} controls width="100%" height="100%"/>
            ) : (
              <Image loader={imageLoader} unoptimized src={post.resourceUrl} alt="Post resource" width={0} height={0} sizes="100vw" style={{ width: '100%', height: 'auto', borderRadius: "8px" }}/>
            )}
          </div>
        ) : (
          <div className="mt-2" />
        )}

          <div className='flex justify-between'>
            <div className="flex column mb-2 gap-2" >
              <div className="flex column w-[40px] gap-1">
                <button disabled={unauth}
                  onClick={() => {handleReactToPost(liked ? "dislike" : "like")}} style={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  { liked ? 
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.25 8.47706L8.99997 14.6671L2.74997 8.47706C2.33772 8.0759 2.013 7.59374 1.79626 7.06092C1.57951 6.52811 1.47543 5.95618 1.49058 5.38117C1.50573 4.80615 1.63977 4.2405 1.88426 3.71984C2.12876 3.19917 2.47841 2.73477 2.9112 2.35587C3.344 1.97698 3.85055 1.6918 4.39898 1.51829C4.9474 1.34479 5.5258 1.28671 6.09777 1.34773C6.66974 1.40874 7.22288 1.58752 7.72236 1.87281C8.22184 2.1581 8.65684 2.54373 8.99997 3.00539C9.34458 2.54708 9.78008 2.16483 10.2792 1.88256C10.7784 1.6003 11.3304 1.4241 11.9007 1.36499C12.4711 1.30588 13.0475 1.36514 13.5939 1.53905C14.1404 1.71296 14.645 1.99779 15.0763 2.37569C15.5075 2.7536 15.8562 3.21646 16.1003 3.7353C16.3445 4.25414 16.4789 4.81779 16.4952 5.39098C16.5115 5.96417 16.4093 6.53455 16.1951 7.06643C15.9808 7.59831 15.6591 8.08024 15.25 8.48206" fill="#F8B4B4"/>
                      <path d="M15.25 8.47706L8.99997 14.6671L2.74997 8.47706C2.33772 8.0759 2.013 7.59374 1.79626 7.06092C1.57951 6.52811 1.47543 5.95618 1.49058 5.38117C1.50573 4.80615 1.63977 4.2405 1.88426 3.71984C2.12876 3.19917 2.47841 2.73477 2.9112 2.35587C3.344 1.97698 3.85055 1.6918 4.39898 1.51829C4.9474 1.34479 5.5258 1.28671 6.09777 1.34773C6.66974 1.40874 7.22288 1.58752 7.72236 1.87281C8.22184 2.1581 8.65684 2.54372 8.99997 3.00539C9.34458 2.54708 9.78008 2.16483 10.2792 1.88256C10.7784 1.6003 11.3304 1.4241 11.9007 1.36499C12.4711 1.30588 13.0475 1.36514 13.5939 1.53905C14.1404 1.71296 14.645 1.99779 15.0763 2.37569C15.5075 2.7536 15.8562 3.21646 16.1003 3.7353C16.3445 4.25414 16.4789 4.81779 16.4952 5.39098C16.5115 5.96417 16.4093 6.53455 16.1951 7.06643C15.9808 7.59831 15.6591 8.08024 15.25 8.48206" stroke="#E02424" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    :
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.25 8.47706L8.99997 14.6671L2.74997 8.47706C2.33772 8.0759 2.013 7.59374 1.79626 7.06092C1.57951 6.52811 1.47543 5.95618 1.49058 5.38117C1.50573 4.80615 1.63977 4.2405 1.88426 3.71984C2.12876 3.19917 2.47841 2.73477 2.9112 2.35587C3.344 1.97698 3.85055 1.6918 4.39898 1.51829C4.9474 1.34479 5.5258 1.28671 6.09777 1.34773C6.66974 1.40874 7.22288 1.58752 7.72236 1.87281C8.22184 2.1581 8.65684 2.54373 8.99997 3.00539C9.34458 2.54708 9.78008 2.16483 10.2792 1.88256C10.7784 1.6003 11.3304 1.4241 11.9007 1.36499C12.4711 1.30588 13.0475 1.36514 13.5939 1.53905C14.1404 1.71296 14.645 1.99779 15.0763 2.37569C15.5075 2.7536 15.8562 3.21646 16.1003 3.7353C16.3445 4.25414 16.4789 4.81779 16.4952 5.39098C16.5115 5.96417 16.4093 6.53455 16.1951 7.06643C15.9808 7.59831 15.6591 8.08024 15.25 8.48206" fill="#ffffff"/>
                      <path d="M15.25 8.47706L8.99997 14.6671L2.74997 8.47706C2.33772 8.0759 2.013 7.59374 1.79626 7.06092C1.57951 6.52811 1.47543 5.95618 1.49058 5.38117C1.50573 4.80615 1.63977 4.2405 1.88426 3.71984C2.12876 3.19917 2.47841 2.73477 2.9112 2.35587C3.344 1.97698 3.85055 1.6918 4.39898 1.51829C4.9474 1.34479 5.5258 1.28671 6.09777 1.34773C6.66974 1.40874 7.22288 1.58752 7.72236 1.87281C8.22184 2.1581 8.65684 2.54372 8.99997 3.00539C9.34458 2.54708 9.78008 2.16483 10.2792 1.88256C10.7784 1.6003 11.3304 1.4241 11.9007 1.36499C12.4711 1.30588 13.0475 1.36514 13.5939 1.53905C14.1404 1.71296 14.645 1.99779 15.0763 2.37569C15.5075 2.7536 15.8562 3.21646 16.1003 3.7353C16.3445 4.25414 16.4789 4.81779 16.4952 5.39098C16.5115 5.96417 16.4093 6.53455 16.1951 7.06643C15.9808 7.59831 15.6591 8.08024 15.25 8.48206" stroke="#6B7280" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  }
                </button>
                <span className={`text-[14px] mt-[2px] ${liked ? "text-red-700" : "text-gray-500"}`}>{likeCount}</span>
              </div>

              <div className="flex column w-auto min-w-[40px] gap-1">
                <button disabled={unauth} onClick={() => {handleOpenPost()}}>
                  <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 14.9997H5C4.33696 14.9997 3.70107 14.7363 3.23223 14.2674C2.76339 13.7986 2.5 13.1627 2.5 12.4997V5.83301C2.5 5.16997 2.76339 4.53408 3.23223 4.06524C3.70107 3.5964 4.33696 3.33301 5 3.33301H15C15.663 3.33301 16.2989 3.5964 16.7678 4.06524C17.2366 4.53408 17.5 5.16997 17.5 5.83301V12.4997C17.5 13.1627 17.2366 13.7986 16.7678 14.2674C16.2989 14.7363 15.663 14.9997 15 14.9997H12.5L10 17.4997L7.5 14.9997Z" stroke="#6B7280" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
                <span className={`text-[14px] mt-[2px] text-gray-500`}>{commentCount}</span>
              </div>

              {!pathname?.startsWith("/feed/post") && !pathname?.startsWith("/social-graph") && 
                <div className="flex column w-auto min-w-[40px] gap-1">
                  <button disabled={unauth} onClick={handleClickShareButton} >
                    <svg width="15" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.66663 6.5H2.83329C2.39127 6.5 1.96734 6.67559 1.65478 6.98816C1.34222 7.30072 1.16663 7.72464 1.16663 8.16667V14.8333C1.16663 15.2754 1.34222 15.6993 1.65478 16.0118C1.96734 16.3244 2.39127 16.5 2.83329 16.5H11.1666C11.6087 16.5 12.0326 16.3244 12.3451 16.0118C12.6577 15.6993 12.8333 15.2754 12.8333 14.8333V8.16667C12.8333 7.72464 12.6577 7.30072 12.3451 6.98816C12.0326 6.67559 11.6087 6.5 11.1666 6.5H10.3333M6.99996 10.6667V1.5M6.99996 1.5L4.49996 4M6.99996 1.5L9.49996 4" stroke="#6B7280" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                </div>
              }
            </div>
          </div>

        { showShareButtons &&
          <>
            <div className='p-0 m-0' style={{ borderBottom: "1px solid #E5E7EB"}} />
            <div className='p-2'>
              <ShareButtons feedId={post.id} 
              hashtags={
                post?.content
                  ? Array.from(post.content.matchAll(/#(\w+)/g), (match) => match[1])
                  : []
              } text={post.content}/>
            </div>
          </>
        }

        { !unauth && detail &&
          <>
            <div className='p-0 m-0' style={{ borderBottom: "1px solid #E5E7EB"}} />
            <div className="p-4">
              <CreateComment postId={post.id} parentId={null} uploadCommentMut={uploadCommentMut} />
            </div>
          </>
        }

        { (!unauth && comments && comments.meta.itemCount !== 0) && detail ? (
          <>
            { (!unauth && comments && comments.meta.itemCount !== 0) && detail ? (
            <>
              {comments.data.filter(comment => !comment.parentId).map((comment, index) => (
                <div key={index} className="p-0">
                  <div className="p-0 m-0 border-b"/>
                  <div className="pt-2">
                    <Comment comment={comment} postId={post.id} refreshComments={refreshComments} />
                  </div>
                </div>
              ))}
              { directCommentCount > 0 &&
                <div className="flex column">
                  { comments.data.length < directCommentCount &&
                    <button onClick={() => {fetchNextCommentsPage()}} style={{ textAlign: 'left', color: '#5850EC', fontSize: '12px', marginLeft: '44px', marginBottom: '10px' }}>
                      Show More ({ comments.meta.itemCount - comments.data.length })
                    </button>
                  }
                  {(directCommentCount - comments.data.length > 0) && (commentsPage > 1) && <span style={{ color: '#696F8C', fontSize: '12px' }}>&nbsp;·&nbsp;</span> }
                  { commentsPage > 1 &&
                    <button onClick={() => { refreshComments() }} style={{ textAlign: 'left', color: '#5850EC', fontSize: '12px', marginBottom: '10px', marginLeft: comments.data.length >= comments.meta.itemCount ? "44px" : 0 }}>
                      Hide
                    </button>
                  }
                </div>
              }
            </>
          ) : <div className="mb-1"/>}
          </>
        ) : <div className="mb-1"/>}

        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseSeeMore}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {!router.asPath.includes("postId") && (
            <MenuItem
              onClick={() => {
                handleOpenPost();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#6B7280"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-corner-down-right mr-2"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 6v6a3 3 0 0 0 3 3h10l-4 -4m0 8l4 -4" />
              </svg>
              <p className="py-1 pr-4 text-base text-gray1">See more</p>
            </MenuItem>
          )}
          {(userData?.id === post.author.id || userData?.roles.includes("ADMIN")) && (
            <MenuItem
              onClick={() => {
                handleDeletePost(post.id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#6B7280"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-trash mr-2"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 7l16 0" />
                <path d="M10 11l0 6" />
                <path d="M14 11l0 6" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>
              <p className="py-1 pr-4 text-base text-gray1">Delete</p>
            </MenuItem>
          )}
        </StyledMenu>
      </div>
    </>
  );
};

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },
  }),
);